<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="联系电话" prop="phone">
        <a-input v-model="form.phone" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="公司简介" prop="companyProfile">
        <editor id="editor1" v-model="form.companyProfile" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { objInfo, objEdit } from '@/api/pages/about-method-data'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单
      form: {
        id: 0,
        companyProfile: '',
        phone: '',
        remark: ''
      },
      open: false,
      rules: {
        companyProfile: [{ required: true, message: '公司简介不能为空', trigger: 'blur' }],
        phone: [{ required: true, message: '联系电话不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    change(value, dateString) {
      console.log(value, dateString)
      this.form.publishTime = dateString
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: 0,
        companyProfile: '',
        phone: '',
        remark: ''
      }
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      objInfo({ id: configId }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
          console.log(this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          objEdit(this.form).then(response => {
            this.$message.success(
              '修改成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
